import React from "react"
import content from "../../content/standards.yaml"

const Standards =  () => (

  <section style={{paddingBottom: '30px', background: '#000000'}} id='standards' className="justify">
    {content.map((value, index) => {
      return(
        <div key={index} style={{marginTop: value.name === 'responsive' ? '0px' : '30px', marginBottom: '0px'}} className={ 'row feature ' + value.name }>
          <div className={ 'twelve columns left'  }>
            {value.title && <h3 style={{ textAlign: "right" }}>{ value.title }</h3>}
            <h4 style={{ textAlign: "right" }}>{ value.subTitle }</h4>
            <p>{ value.body }</p>
          </div>
        </div>
      )
    })}
  </section>
)
export default Standards;