import React from "react"
import content from '../../content/title6.yaml';

const Title6 = () => (
  <section id="call-to-action">
    <div id="contactUs" style={{position: 'relative', top: '-60px'}}/>
    <div style={{
      display: "flex",
      justifyContent: "space-between",
      width: "96%",
      maxWidth: "1200px",
      margin: "0 auto"
    }}>
      <img style={{ width: "50px", height: "50px" }} src="images/dice.webp" alt=""/>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}><h1
        style={{ margin: 0 }}>{content.title}</h1></div>


    </div>
  </section>
)
export default Title6;