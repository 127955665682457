import React from "react"
import content from '../../content/title4.yaml';

const Title4 = () => (
    <section id="call-to-action">
      <div id="aboutUs" style={{position: 'relative', top: '-60px'}}/>
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        width: "96%",
        maxWidth: "1200px",
        margin: "0 auto"
      }}>
       
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}><h1
          style={{ margin: 0 }}>{content.title}</h1></div>
        <img style={{ width: "50px", height: "50px" }} src="images/dice.webp" alt=""/>

      </div>
    </section>
)
export default Title4;