import React from "react"
import content from "../../content/developmentServices.yaml"

const Services = () => (

  <section style={{paddingBottom: '30px'}} id='ineorSolutions'>
    {content.map((value, index) => {
      return(
        <div key={index} style={{marginTop: value.name === 'responsive' ? '0px' : '30px', marginBottom: '0px'}} className={ 'row feature justify ' + value.name }>
          <div className={ 'twelve columns left'  }>
            {value.title && <h3>{ value.title }</h3>}
            <h4>{ value.subTitle }</h4>
            <p>{ value.body }</p>
          </div>
          
       </div>
     )
   })}
  </section>
)
export default Services;