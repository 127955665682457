import React from "react"
import content from "../../content/mainTitle.yaml"

const MainTitle = () => (
  <section id="mainTitle">
    <div className="row">
      <div className="twelve columns">
        <div className="hero-image">
          <img src={ content.image } alt="" className="animated fadeInLeft" />
        </div>
      </div>
    </div>
  
  </section>
)
export default MainTitle;