import React from "react"
import content from "../../content/howDoWeDoIt.yaml"

const HowDoIt = () => (

  <section style={{paddingBottom: '30px'}} id='howDoWeDoIt' className="justify">
    {content.map((value, index) => {
      return(
        <div key={index} style={{marginTop: value.name === 'responsive' ? '0px' : '30px', marginBottom: '0px'}} className={ 'row feature ' + value.name }>
          <div className={ 'twelve columns left'  }>
            {value.title && <h3>{ value.title }</h3>}
            <h4>{ value.subTitle }</h4>
            <p>{ value.body }</p>
          </div>

        </div>
      )
    })}
  </section>
)
export default HowDoIt;