import React from "react"
import content from "../../content/cases.yaml"

const Cases = () => (

  <section id='softwareIntegration' style={{background: '#000000'}} className="justify">
    <div style={{border: '15px solid #343333', margin: '20px auto', maxWidth: '1270px', paddingBottom: '40px'}}>
      {content.map((value, index) => {
        return(

          <div key={index} style={{ marginTop: value.name === "responsive" ? "0px" : "30px", marginBottom: "0px" }}
               className={"row feature " + value.name}>
            <div className={"twelve columns left"}>
              {value.title && <h3 style={{ textAlign: "right" }}>{value.title}</h3>}
              <h4 style={{ textAlign: "right" }}>{value.subTitle}</h4>
              <p>{value.body}</p>
            </div>

          </div>
        )
      })}
    </div>
  </section>
)
export default Cases;