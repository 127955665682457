import React from "react"
import content from "../../content/aboutUs.yaml"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const About = () => (

  <section style={{paddingBottom: '30px', background: '#000000'}} id='aboutUs'>
    {content.map((value, index) => {
      return(
        <div key={index} style={{marginTop: value.name === 'responsive' ? '0px' : '30px', marginBottom: '0px'}} className={ 'row feature ' + value.name }>
          <div className={ 'twelve columns left justify'  }>
            {value.title && <h3 style={{ textAlign: "left" }}>{ value.title }</h3>}
            <h4 style={{ textAlign: "left" }}>{ value.subTitle }</h4>
            <p>{ value.body }</p>
            {value.button1 && <div className="buttons">
              <AnchorLink className="button black" to={ value.button1.to }>{ value.button1.label }</AnchorLink>
            </div>}
            {value.button2 && <div className="buttons">
              <AnchorLink className="button black" to={ value.button2.to }>{ value.button2.label }</AnchorLink>
            </div>}
          </div>

        </div>
      )
    })}
  </section>
)
export default About;