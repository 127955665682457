import React from "react"
import content from "../../content/contact.yaml"
import Modal from "react-modal"
import { ButtonBase } from "@material-ui/core"

Modal.setAppElement("#___gatsby")

const Contact = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const openModal = () => {
    setIsOpen(true);
    setIsLoading(true);
    initializeHubspotForm();
    document.body.style.overflow = 'hidden';
  }

  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = 'unset';
  }

  const initializeHubspotForm = async () => {
    if("hbspt" in window){
      console.log("window", window);
      window.hbspt.forms.create({
        region: "eu1",
        portalId: "25577968",
        formId: "b821a056-79a2-4606-8388-58785295bf2d",
        target: "#form-container",
        onFormReady: () => setIsLoading(false)
      });
    }else{
      setTimeout(initializeHubspotForm, 500);
    }
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "90%",
      height: "90%",
      maxWidth: "790px",
      maxHeight: "900px",
      padding: 30,
    },
    overlay:{
      zIndex: 999
    }
  }

  return (
    <section style={{ paddingBottom: "30px", background: "#000000" }} id="standards" className="justify">
      {content.map((value, index) => {
        return (
          <div key={index} style={{ marginTop: value.name === "responsive" ? "0px" : "30px", marginBottom: "0px" }}
               className={"row feature " + value.name}>
            <div className={"twelve columns left"}>
              {value.title && <h3 style={{ textAlign: "right" }}>{value.title}</h3>}
              <h4 style={{ textAlign: "right" }}>{value.subTitle}</h4>
              <p>{value.body}</p>
            </div>
          </div>
        )
      })}
      <div>
        <button className="btn-contact" onClick={openModal}>Contact us!</button>
      </div>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Kontaktirajte nas"
        className="ReactModal__Content-custom"
      >
        <div className={"contact-title-box"}>
          <div className="spacer"/>
          <div style={{textAlign: 'center', marginBottom: '40px', fontSize: '30px'}}>Contact Us</div>
          <ButtonBase className="btn-close" onClick={() => closeModal()}>
            Close&nbsp;X
          </ButtonBase>
        </div>
        {isLoading && <div className="loader"></div>}
        <div id="form-container"></div>
      </Modal>
    </section>
  )
}
export default Contact;