import React from "react"

import Layout from "../components/layout"

import MainTitle from "../components/mainTitle"
import IneorSolutions from "../components/ineorSolutions"
import SoftwareIntegration from "../components/softwareIntegration"
import DevelopmentServices from "../components/developmentServices"
import Standards from "../components/standards"
import HowDoWeDoIt from "../components/howDoWeDoIt"
import Title from "../components/title"
import AboutUs from "../components/aboutUs"
import Title2 from "../components/title2"
import Title3 from "../components/title3"
import Title4 from "../components/title4"
import Title5 from "../components/title5"
import Cases from "../components/cases"
import Cases2 from "../components/cases2"
import Cases3 from "../components/cases3"
import Cases4 from "../components/cases4"
import Contact from "../components/contact"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import Title6 from "../components/title6"
import { Helmet } from "react-helmet"

const Index = () => {
  const { sections } = useSiteMetadata()
  const availableSections = {
    "mainTitle": MainTitle,
    "ineorSolutions": IneorSolutions,
    "developmentServices": DevelopmentServices,
    "softwareIntegration": SoftwareIntegration,
    "howDoWeDoIt": HowDoWeDoIt,
    "standards": Standards,
    "cases": Cases,
    "cases2": Cases2,
    "cases3": Cases3,
    "cases4": Cases4,
    "aboutUs": AboutUs,
    "title": Title,
    "title2": Title2,
    "title3": Title3,
    "title4": Title4,
    "title5": Title5,
    "title6": Title6,
    "contact": Contact
  }

  return(
    <>
      <Helmet>
        <script
          src="https://code.jquery.com/jquery-3.3.1.min.js"
          integrity="sha256-FgpCb/KJQlLNfOu91ta32o/NMZxltwRo8QtmkMRdAu8="
          crossOrigin="anonymous"
        />

        <script charSet="utf-8" type="text/javascript" src="//js-eu1.hsforms.net/forms/shell.js"/>
      </Helmet>
      <Layout>
        { sections.map((section, index) => {
          let Tagname = availableSections[section]
          return <Tagname key={index}/>
        }) }
      </Layout>
    </>
  )
}
export default Index;